import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Section1 from '../pagePartials/meditation/Section1';
import Section2 from '../pagePartials/new/Section2';
import Section3 from '../pagePartials/meditation/Section3';
import Section4 from '../pagePartials/meditation/Section4';
import Section5 from '../pagePartials/meditation/Section5';

const Index = props => (
  <Layout location={props.location}>
    <div className="new">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  </Layout>
);

Index.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Index;
